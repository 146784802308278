import {
  News,
  Employee,
  Event,
  ImageData,
  Link,
  Menu,
  MenuItem,
  Page,
  Section,
  SeoData,
  SeoSocialData,
  Settings,
} from './types';
import { get, flatten, uniqBy } from 'lodash';
import { fixImageUrl, placeholderImage } from './image';
import { vimeoPresets } from '@components/vimeo';

// images can always be missing as it is possible to delete them from the media
// library. if an image is required and missing this function will return a p
// placeholder.
export function parseImage<T extends boolean>(
  data: any,
  required: T,
): T extends true ? ImageData : ImageData | null {
  if (!data) {
    return (required ? placeholderImage : null) as T extends true
      ? ImageData
      : ImageData | null;
  }

  const { id, attributes } = data;
  const {
    width,
    height,
    alternativeText,
    formats,
    name,
    hash,
    ext,
    mime,
    url,
  } = attributes;

  return {
    id,
    width,
    height,
    alternativeText,
    thumbnail: formats?.thumbnail ? formats.thumbnail : null,
    small: formats?.small ? formats.small : null,
    medium: formats?.medium ? formats.medium : null,
    large: formats?.large ? formats.large : null,
    xlarge: formats?.xlarge ? formats.xlarge : null,
    xxlarge: formats?.xxlarge ? formats.xxlarge : null,
    hash,
    ext,
    mime,
    url,
    name,
  };
}

export async function parsePage(data: any): Promise<Page | null> {
  if (!data || !(data.id && data.attributes)) {
    return null;
  }

  const { id, attributes } = data;
  const { url, slug, title, category, locale, sections, seo } = attributes;

  const parsedSeo = parseSeo(seo);

  return {
    id,
    url,
    slug,
    title,
    sections: await parseSections(sections),
    locale,
    seo: parsedSeo,
  };
}

export function parseSeo(data: any): SeoData | null {
  if (data == null) {
    return null;
  }

  const metaTitle = data.metaTitle ?? '';
  const metaDescription = data.metaDescription ?? '';
  const metaImageUrl = parseImage(data.metaImage.data, true).url;
  const metaSocial = data.metaSocial.map(parseSocialData);
  const keywords = data.keywords;
  const metaRobots = data.metaRobots;
  const structuredData = data.structuredData;
  const canonicalURL = data.canonicalURL;

  return {
    metaTitle,
    metaDescription,
    metaImageUrl,
    metaSocial,
    keywords,
    metaRobots,
    structuredData,
    canonicalURL,
  };
}

export function parseSocialData(data: any): SeoSocialData {
  const socialNetwork = data.socialNetwork ?? '';
  const title = data.title ?? '';
  const description = data.description ?? '';
  const image = parseImage(data.image.data, false);

  return { socialNetwork, title, description, image };
}

function parseLink(link: any): Link | null {
  return link
    ? {
        text: link.text,
        url: link.url,
      }
    : null;
}

function parseTeamEmployees(data: {
  data: {
    id: number;
    attributes: {
      title: string;
      employees: {
        data: {
          id: number;
          attributes: {
            firstName: string;
            lastName: string;
            middleName: string;
            titles: string;
            suffix: string;
            description: string;
            portrait: {
              data: any;
            };
            bio?: string;
            linkedIn?: string;
          };
        }[];
      };
    };
  }[];
}): Employee[] {
  const employees = data.data.map((category) => {
    return category.attributes.employees.data.map((employee) => {
      const id = employee.id;

      const firstName = employee.attributes.firstName;
      const lastName = employee.attributes.lastName;
      const middleName = get(employee, 'attributes.middleName', '');
      const titlesSalutation = get(employee, 'attributes.titlesSalutation', '');
      const suffix = get(employee, 'attributes.suffix', '');

      const description = employee.attributes.description;

      const portrait = parseImage(employee.attributes.portrait.data, false);

      const bio = get(employee, 'attributes.bio', '');
      const linkedIn = get(employee, 'attributes.linkedIn', '');

      return {
        id,
        firstName,
        lastName,
        middleName,
        titlesSalutation,
        suffix,
        description,
        portrait,
        bio,
        linkedIn,
      };
    });
  });

  return uniqBy(flatten(employees), 'id');
}

function parseEvents(
  data: {
    data: {
      id: number;
      attributes: {
        title: string;
        paragraph: string;
        events: {
          data: {
            id: number;
            attributes: {
              title: string;
              description: string;
              start: string;
              end: string;
              location: string;
              link: Link;
            };
          }[];
        };
      };
    }[];
  },
  limit: number,
): Event[] {
  const events = data.data.map((category) => {
    return category.attributes.events.data.map((event) => {
      const id = event.id;
      const title = event.attributes.title;
      const description = event.attributes.description || '';
      const location = event.attributes.location;
      const start = event.attributes.start;
      const end = event.attributes.end;
      const link = parseLink(event.attributes.link);

      return {
        id,
        title,
        description,
        start,
        end,
        location,
        link,
      };
    });
  });

  return uniqBy(flatten(events), 'id').slice(0, limit);
}

export function parseSections(data: any[]): Section[] {
  if (!data) {
    return [];
  }

  return data
    .map((section: any) => {
      try {
        const { __typename } = section;
        switch (__typename) {
          case 'ComponentSectionsImage':
            return {
              sectionName: __typename,
              image: parseImage(section.image.data, true),
            };
          case 'ComponentSectionsImageText':
            return {
              sectionName: __typename,
              image: parseImage(section.image.data, true),
              title: section.imageTextTitle,
              subTitle: section.subTitle,
              link: parseLink(section.link),
              layout: section.layout || 'ImageLeft',
              paragraph: section.imageTextParagraph || null,
            };
          case 'ComponentSectionsRichText':
            return {
              sectionName: __typename,
              title: section.optionalTitle || null,
              html: fixImageUrl(section.content),
            };
          case 'ComponentSectionsVideo':
            return {
              sectionName: __typename,
              video: JSON.parse(section.video),
            };
          case 'ComponentSectionsCards':
            return {
              sectionName: __typename,
              elements: section.elements.map((el: any) => ({
                overline: el.overline,
                title: el.title,
                html: el.content,
                link: parseLink(el.link),
              })),
            };
          case 'ComponentSectionsHero':
            return {
              sectionName: __typename,
              title: section.title,
              backgroundFocalpoint: section.backgroundFocalpoint,
              customFocalpoint: section.customFocalpoint,
              cover: parseImage(section.cover.data, true),
              overline: section.overlineHero || null,
            };
          case 'ComponentSectionsQuote':
            return {
              sectionName: __typename,
              title: section.title,
              author: section.author,
              authorDescription: section.authorDescription,
            };
          case 'ComponentSectionsTitleParagraphLinks':
            return {
              sectionName: __typename,
              title: section.title,
              paragraph: section.paragraph,
              links: section.links.map((el: any) => parseLink(el)),
            };
          case 'ComponentSectionsTeam':
            return {
              sectionName: __typename,
              title: section.title,
              lead: section.lead,
              employees: parseTeamEmployees(section.employeeCategories),
            };
          case 'ComponentSectionsPageTitle':
            return {
              sectionName: __typename,
              title: section.title,
            };
          case 'ComponentSectionsContact':
            return {
              sectionName: __typename,
              id: section.id,
              title: section.title,
              paragraph: section.paragraph,
              successMsg: section.successMsg,
              errorMsg: section.errorMsg,
              showBg: section.showBg,
              function: section.function.map(
                ({ name }: { name: string }) => name,
              ),
              surgicalSpecialty: section.surgicalSpecialty.map(
                ({ name }: { name: string }) => name,
              ),
            };
          case 'ComponentSectionsEvents':
            return {
              sectionName: __typename,
              title: section.title,
              paragraph: section.paragraph,
              events: parseEvents(
                section.eventCategories,
                section.numberOfEventsToDisplay,
              ),
            };
          case 'ComponentSectionsButton':
            return {
              sectionName: __typename,
              links: section.buttonLinks.map((el: any) => parseLink(el)),
            };
          case 'ComponentSectionsVideoText':
            return {
              sectionName: __typename,
              vimeoUrl: section.vimeoUrl,
              videoPreset: vimeoPresets[
                `${section.videoPreset}` as keyof typeof vimeoPresets
              ]
                ? section.videoPreset
                : null,
              title: section.videoTextTitle,
              subTitle: section.videoTextSubTitle,
              link: parseLink(section.videoTextLink),
              layout: section.videoTextLayout || 'ImageLeft',
              paragraph: section.videoTextTextParagraph || null,
              videoStartAtSecond: section.videoStartAtSecond || null,
            };
          case 'ComponentSectionsHeroVideo':
            return {
              sectionName: __typename,
              vimeoUrl: section.vimeoUrl,
              title: section.titleHeroVideo || null,
              overline: section.overlineHeroVideo || null,
              videoStartAtSecond: section.heroVideoStartAtSecond || null,
            };
          case 'ComponentSectionsVimeo':
            return {
              sectionName: __typename,
              vimeoUrl: section.vimeoVimeoUrl,
              videoPreset: vimeoPresets[
                `${section.vimeoVideoPreset}` as keyof typeof vimeoPresets
              ]
                ? section.vimeoVideoPreset
                : null,
              videoStartAtSecond: section.vimeoVideoStartAtSecond || null,
            };
          case 'ComponentSectionsHighlight':
            return {
              sectionName: __typename,
              text: section.text,
            };
          case 'ComponentSectionsHtml':
            return {
              sectionName: __typename,
              html: section.html,
            };
          default:
            throw new Error(`Unknown section: ${__typename}`);
        }
      } catch (e: unknown) {
        console.error(e);
      }
    })
    .filter(Boolean) as Section[];
}

export function parseMenu(menu: any): Menu {
  const items = get(menu, 'data[0].attributes.items.data', []);

  return { items: items.map(parseMenuItem) };
}

export function parseMenuItem(menuItem: any): MenuItem {
  const title = get(menuItem, 'attributes.title');
  const href = get(menuItem, 'attributes.url');
  const target = get(menuItem, 'attributes.target');
  const children = get(menuItem, 'attributes.children.data');

  return {
    title,
    href,
    target,
    children: children.length > 0 ? children.map(parseMenuItem) : undefined,
  };
}

export function parseSettings(data: any): Settings {
  const address = get(data, 'attributes.Footer.address', '');
  const texts = get(data, 'attributes.texts', {});
  const socialLinks = get(data, 'attributes.Footer.socialLinks', []).map(
    (l: any) => ({ ...l, image: parseImage(l.image.data, true) }),
  );

  const trackingId = get(
    data,
    'attributes.GoogleAnalytics.trackingId',
    undefined,
  );
  const consentInfo = get(
    data,
    'attributes.GoogleAnalytics.consentInfo',
    undefined,
  );
  const acceptAllLabel = get(
    data,
    'attributes.GoogleAnalytics.acceptAllLabel',
    'Accept All',
  );
  const declineAllLabel = get(
    data,
    'attributes.GoogleAnalytics.declineAllLabel',
    'Decline All',
  );

  return {
    footer: { address, socialLinks },
    texts,
    googleAnalytics: {
      trackingId,
      consentInfo,
      acceptAllLabel,
      declineAllLabel,
    },
  };
}

export function parseNewsList(data: any[]): News[] {
  return data.map((post) => parseNews(post));
}

export function parseNews(news: any): News {
  const title = news?.attributes?.title ?? '';
  const content = parseSections(get(news, 'attributes.content'));
  const image = parseImage(get(news, 'attributes.image.data'), true);
  const publishDate = news?.attributes?.publishDate ?? '';
  const id = news.id;
  const intro = news?.attributes?.intro ?? '';
  const slug = news?.attributes?.slug ?? '';
  const seo = parseSeo(news?.attributes?.seo);
  const locale = news?.attributes?.locale;
  return { title, content, image, id, publishDate, intro, slug, seo, locale };
}
